



































import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  props: {
    background: {
      type: String,
      required: true
    },
    icon: {
      type: String,
      required: true
    },
    testimonial: {
      type: String,
      required: true
    },
    user: {
      type: Object,
      required: true
    }
  },

  setup() {}
});
