



























































































import { reactive, toRefs, ref, SetupContext, Ref } from '@vue/composition-api';
import { useAuthActions } from '@/store';
import ATextInput from '@/components/atoms/ATextInput.vue';
import AButton from '@/components/atoms/AButton.vue';
import AImage from '@/components/atoms/AImage.vue';
import axios from 'axios';
import MAuthInputs from './MAuthInputs.vue';

interface IUserForm {
  email: string;
  password: string;
  terms: boolean;
}

interface IUi {
  msg: string;
  type: string;
  loading: boolean;
}

export default {
  name: 'Signup',

  components: {
    MAuthInputs,
    ATextInput,
    AButton,
    AImage,
    MConsentTermsPolicy: () => import('@/components/molecules/MConsentTermsPolicy.vue')
  },

  props: {
    isInvite: {
      type: Boolean,
      default: false
    },
    inviteId: {
      type: String,
      default: ''
    }
  },

  setup(props: any, context: SetupContext) {
    const { signup } = useAuthActions(['signup']);
    const dialog: Ref<boolean> = ref(false);
    const show1: Ref<boolean> = ref(false);
    const state: IUserForm = reactive({
      email: '',
      password: '',
      terms: false
    });

    const param: string = (context.root.$route.query.email as string) || '';
    state.email = param;

    function toggleShowPassword() {
      show1.value = !show1.value;
    }

    const ui: IUi = reactive({
      msg: '',
      type: 'success',
      loading: false
    });

    async function triggerHubspot() {
      const API_ENDPOINT = process.env.VUE_APP_HUBSPOT_EVENT;

      const data = {
        event_type: 'sign_up',
        email: state.email
      };

      try {
        const resp = await axios.post(API_ENDPOINT, data, {
          headers: {
            'Content-Type': 'application/json'
          }
        });
      } catch (error) {
        console.log(error);
      }
    }

    async function createHubspot() {
      const API_ENDPOINT = process.env.VUE_APP_HUBSPOT_EVENT_CREATE;

      const data = {
        id: props.inviteId,
        event_type: 'sign_up',
        username: state.email
      };

      try {
        const resp = await axios.post(API_ENDPOINT, data, {
          headers: {
            'Content-Type': 'application/json'
          }
        });
      } catch (error) {
        console.log(error);
      }
    }

    const submit = async (): Promise<void> => {
      ui.loading = true;
      if (context.root.$route.params && context.root.$route.params.slug) {
        localStorage.setItem('inviteSlug', context.root.$route.params.slug);
        triggerHubspot();
        createHubspot();
      }
      try {
        await signup({ email: state.email, password: state.password });
        ui.type = 'success';
        ui.msg = 'An email confirmation has been sent to your address';
      } catch (err) {
        ui.msg = (err as Error).message.includes('409')
          ? 'Email already in use'
          : 'Could not signup';
        ui.type = 'error';
      }
      ui.loading = false;
    };

    return {
      ...toRefs(state),
      triggerHubspot,
      ...toRefs(ui),
      submit,
      dialog,
      toggleShowPassword,
      show1
    };
  }
};
