var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-layout',{staticClass:"invite__container d-flex flex-column justify-center align-center"},[(_vm.pass !== _vm.invitePages[_vm.getInvitee].password)?_c('div',{staticClass:"invite__wrapper-password d-flex align-center flex-column"},[_c('div',{staticClass:"invite__password-title"},[_c('v-btn',{staticClass:"invite__password-title-button font-weight-bold",attrs:{"color":_vm.getInviteColor,"small":"","dark":"","rounded":"","depressed":""}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-telegram")]),_vm._v("Invite for "+_vm._s(_vm.getInvitee)+" ")],1)],1),_c('validation-observer',{staticClass:"invite__password d-flex",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('validation-provider',{attrs:{"slim":"","rules":("password:" + (_vm.invitePages[_vm.getInvitee].password))},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"type":_vm.show1 ? 'text' : 'password',"depressed":"","label":"Password","outlined":"","required":"","hint":"See email for password..."},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }handleSubmit(function () {
              _vm.pass = _vm.inputPassword;
            })}},model:{value:(_vm.inputPassword),callback:function ($$v) {_vm.inputPassword=$$v},expression:"inputPassword"}})]}}],null,true)}),_c('v-btn',{staticClass:"invite__password-button text-uppercase",attrs:{"outlined":"","x-large":"","hide-details":"","depressed":""},on:{"click":function($event){handleSubmit(function () {
            _vm.pass = _vm.inputPassword;
          })}}},[_vm._v("Open")])]}}],null,false,1054446738)})],1):_c('div',{staticClass:"d-flex align-center flex-row invite__wrapper-type3"},[_c('div',{staticClass:"d-flex align-center flex-row invite__wrapper-type2"},[_c('div',{staticClass:"d-flex align-center flex-row invite__wrapper-type",class:{
          'invite__wrapper-school':
            _vm.invitePages[_vm.getInvitee] && _vm.invitePages[_vm.getInvitee].user === 'school',
          'invite__wrapper-employer':
            _vm.invitePages[_vm.getInvitee] && _vm.invitePages[_vm.getInvitee].user === 'employer',
          'invite__wrapper-teacher':
            _vm.invitePages[_vm.getInvitee] && _vm.invitePages[_vm.getInvitee].user === 'teacher',
          'invite__wrapper-parent':
            _vm.invitePages[_vm.getInvitee] && _vm.invitePages[_vm.getInvitee].user === 'parent',
          'invite__wrapper-student':
            _vm.invitePages[_vm.getInvitee] && _vm.invitePages[_vm.getInvitee].user === 'student',
          'invite__wrapper-sponsor':
            _vm.invitePages[_vm.getInvitee] && _vm.invitePages[_vm.getInvitee].user === 'sponsor'
        }},[_c('div',{staticClass:"d-flex flex-column mb-auto"},[(_vm.invitePages[_vm.getInvitee] && _vm.invitePages[_vm.getInvitee].user)?_c('div',[_c('v-btn',{staticClass:"invite__password-title-button font-weight-bold",attrs:{"rounded":"","small":"","outlined":"","depressed":""}},[_vm._v(" "+_vm._s(((_vm.invitePages[_vm.getInvitee] && _vm.invitePages[_vm.getInvitee].user) + " Invite"))+" ")])],1):_vm._e(),_c('div',[_c('span',{staticClass:"invite__title text-center black--text"},[(_vm.invitePages[_vm.getInvitee] && _vm.invitePages[_vm.getInvitee].user)?_c('v-icon',{staticClass:"invite__title-icon mb-2",attrs:{"color":_vm.getInviteColor,"x-large":""}},[_vm._v(" mdi-telegram ")]):_vm._e(),_vm._v(" Invite for "),_c('span',{class:{
                  'blue--text':
                    _vm.invitePages[_vm.getInvitee] && _vm.invitePages[_vm.getInvitee].user === 'school',
                  'purple--text':
                    _vm.invitePages[_vm.getInvitee] && _vm.invitePages[_vm.getInvitee].user === 'employer',
                  'pink--text':
                    _vm.invitePages[_vm.getInvitee] && _vm.invitePages[_vm.getInvitee].user === 'teacher',
                  'yellow--text':
                    _vm.invitePages[_vm.getInvitee] && _vm.invitePages[_vm.getInvitee].user === 'parent',
                  'green--text':
                    _vm.invitePages[_vm.getInvitee] && _vm.invitePages[_vm.getInvitee].user === 'student',
                  'red--text':
                    _vm.invitePages[_vm.getInvitee] && _vm.invitePages[_vm.getInvitee].user === 'sponsor'
                }},[_vm._v(" "+_vm._s(_vm.getInvitee)+" ")])],1)]),_c('div',[_c('iframe',{staticClass:"invite__video",attrs:{"src":("https://www.youtube.com/embed/" + (_vm.invitePages[_vm.getInvitee].video)),"frameborder":"0","allow":"accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture","allowfullscreen":""}})])]),_c('div',{staticClass:"invite__wrapper-column-right d-flex flex-column mb-auto justify-start"},[_c('div',{staticClass:"invite__resources-title3 text-center black--text"},[_vm._v("What's next?")]),_c('div',[_c('a',{staticStyle:{"text-decoration":"none"},attrs:{"href":"https://app.hubspot.com/meetings/dericklee/programinvite","target":"_blank"}},[_c('v-btn',{staticClass:"invite__cta2 font-weight-bold",attrs:{"color":"grey darken-4","x-large":"","dark":"","depressed":""}},[_vm._v("Schedule 30-Min Meeting")])],1)]),_c('div',{staticClass:"invite__accept-decline d-flex flex-row"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('a',{staticClass:"invite__cta-accept-decline-link",staticStyle:{"text-decoration":"none"},attrs:{"href":"https://www.pilotcity.com/signup","target":"_blank"}},[_c('v-btn',_vm._g(_vm._b({staticClass:"invite__cta-accept-decline mx-auto font-weight-bold",attrs:{"color":"green","large":"","dark":"","depressed":""}},'v-btn',attrs,false),on),[_vm._v("Sign-up")])],1)]}}])},[_c('span',[_vm._v("Sign-up for account")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('a',{staticClass:"invite__cta-accept-decline-link",staticStyle:{"text-decoration":"none"},attrs:{"href":"https://share.hsforms.com/1FhBLzFLZSPSHPUk3TEvv0w1h6bj","target":"_blank"}},[_c('v-btn',_vm._g(_vm._b({staticClass:"invite__cta-accept-decline mx-auto",attrs:{"color":"red","large":"","dark":"","depressed":""}},'v-btn',attrs,false),on),[_vm._v(" Decline ")])],1)]}}])},[_c('span',[_vm._v("Decline to participate")])])],1),_c('div',{staticClass:"invite__resources"},[_c('div',{staticClass:"invite__resources-title3 text-center black--text mb-1"},[_vm._v(" Can I learn more? ")]),_c('div',[_c('a',{staticStyle:{"text-decoration":"none"},attrs:{"href":"https://www.pilotcity.com","target":"_blank"}},[_c('v-btn',{staticClass:"invite__cta",attrs:{"small":"","rounded":"","outlined":"","depressed":""}},[_vm._v(" Browse PilotCity.com ")])],1)]),_c('div',[_c('a',{staticStyle:{"text-decoration":"none"},attrs:{"href":"https://www.pilotcity.com/","target":"_blank"}},[_c('v-btn',{staticClass:"invite__cta",attrs:{"small":"","rounded":"","outlined":"","depressed":""}},[_vm._v(" Explore current projects ")])],1)]),[_c('div',{staticClass:"text-center"},[_c('v-dialog',{attrs:{"width":"90%"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"invite__cta",attrs:{"small":"","rounded":"","outlined":"","depressed":""}},'v-btn',attrs,false),on),[_vm._v(" Student & Parent Testimonials ")])]}}])},[_c('v-card',_vm._l((_vm.inviteTestimonials),function(item,index){return _c('Testimonial',{key:index,attrs:{"background":item.background,"icon":item.icon,"testimonial":item.testimonial,"user":item.user}})}),1)],1)],1)]],2),_c('div',{staticClass:"invite__questions-tip black--text text-center"},[_c('v-icon',{attrs:{"small":"","color":"grey darken-3"}},[_vm._v("mdi-comment-question-outline")]),_vm._v(" GOT QUESTIONS? WE GOT ANSWERS"),_c('v-icon',{attrs:{"small":"","color":"grey darken-3"}},[_vm._v("mdi-arrow-bottom-right-thick")])],1)])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }