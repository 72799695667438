/* eslint-disable import/prefer-default-export */
import { ObjectId } from 'bson';
import axios from 'axios';
import { ActionTypes } from '../store/modules/tools/actions';
import store, { useDbGetters } from '../store';

interface Referral {
  sentTo: string;
  timestamp: Date;
}

export const fetchUserOrganizations = async (userId: ObjectId) => {
  try {
    const res = await axios.get(
      `${process.env.VUE_APP_SPONSOR_SERVICE}/user-org?user_id=${userId}`,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('apollo-token')}`
        }
      }
    );
    return res.data.data;
  } catch (error) {
    return error;
  }
};

export const updateStakeholder = async data => {
  try {
    const res = await axios.patch(`${process.env.VUE_APP_SPONSOR_SERVICE}/change-role`, data, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('apollo-token')}`
      }
    });
    return res.data.data;
  } catch (error) {
    return error;
  }
};

export const fetchStakeholdersInvitation = async organizationId => {
  try {
    const res = await axios.get(
      `${process.env.VUE_APP_SPONSOR_SERVICE}/pending-stakeholders?organization_id=${organizationId}`,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('apollo-token')}`
        }
      }
    );
    return res.data.data;
  } catch (error) {
    return error;
  }
};

export const fetchStakeholders = async organizationId => {
  try {
    const res = await axios.get(
      `${process.env.VUE_APP_SPONSOR_SERVICE}/current-stakeholders?organization_id=${organizationId}`,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('apollo-token')}`
        }
      }
    );
    return res.data.data;
  } catch (error) {
    return error;
  }
};

export const addStakeholderToOrg = async data => {
  try {
    const res = await axios.patch(
      `${process.env.VUE_APP_SPONSOR_SERVICE}/add-registered-user`,
      data,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('apollo-token')}`
        }
      }
    );
    store.dispatch(`tools/${ActionTypes.showSnackbar}`, {
      message: 'User added to Organization successfully',
      type: 'success',
      isShowSnackbar: true
    });
    return res.data.data;
  } catch (error) {
    return error;
  }
};

export const fetchTokens = async (orgId): Promise<number> => {
  try {
    const res = await axios.get(
      `${process.env.VUE_APP_SPONSOR_SERVICE}/org-tokens?organization_id=${orgId}`,
      {
        headers: {
          'Content-Type': 'application/json'
        }
      }
    );
    return res.data.data;
  } catch (error) {
    return error;
  }
};
