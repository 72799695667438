












































































































import { reactive, toRefs, SetupContext } from '@vue/composition-api';
import { ActionTypes } from '@/store/modules/auth/actions';
import { useAuthActions, useUserState } from '@/store';
import Loading from '@/components/Loading.vue';
import ATextInput from '@/components/atoms/ATextInput.vue';
import AButton from '@/components/atoms/AButton.vue';
import AImage from '@/components/atoms/AImage.vue';
import { onLogin } from '@/vue-apollo';
import axios from 'axios';
import MAuthInputs from './MAuthInputs.vue';

interface IUserForm {
  email: string;
  password: string;
  resetEmail: string;
  show1: boolean;
  dialog: boolean;
  error: string;
}

interface IUi {
  msg: string;
  type: string;
  loading: boolean;
}
export default {
  components: {
    Loading,
    MAuthInputs,
    ATextInput,
    AButton,
    AImage
  },

  props: {
    isInvite: {
      type: Boolean,
      default: false
    },
    inviteId: {
      type: String,
      default: ''
    }
  },

  setup(props: any, context: SetupContext) {
    const state: IUserForm = reactive({
      email: '',
      password: '',
      resetEmail: '',
      show1: false,
      dialog: false,
      error: ''
    });
    const ui: IUi = reactive({
      msg: '',
      type: 'success',
      loading: false
    });
    const {
      root: { $router, $route }
    } = context;

    function toggleShowPassword() {
      state.show1 = !state.show1;
    }

    const { loginUser } = useAuthActions([ActionTypes.loginUser]);
    const { sendResetPassword } = useAuthActions(['sendResetPassword']);

    const sendResetPasswordEmail = async (): Promise<void> => {
      ui.loading = true;
      try {
        await sendResetPassword({ email: state.resetEmail });
        ui.type = 'success';
        ui.msg = 'Reset password email has been sent';
      } catch (err) {
        ui.msg = 'Reset password email could not be sent';
        ui.type = 'error';
      }
      ui.loading = false;
    };

    async function triggerHubspot() {
      const API_ENDPOINT = process.env.VUE_APP_HUBSPOT_EVENT;

      const data = {
        event_type: 'sign_in',
        email: state.email
      };

      try {
        const resp = await axios.post(API_ENDPOINT, data, {
          headers: {
            'Content-Type': 'application/json'
          }
        });
      } catch (error) {
        console.log(error);
      }
    }

    async function createHubspot() {
      const API_ENDPOINT = process.env.VUE_APP_HUBSPOT_EVENT_CREATE;

      const data = {
        id: props.inviteId,
        event_type: 'sign_in',
        username: state.email
      };

      try {
        const resp = await axios.post(API_ENDPOINT, data, {
          headers: {
            'Content-Type': 'application/json'
          }
        });
      } catch (error) {
        console.log(error);
      }
    }

    const login = async (): Promise<void> => {
      try {
        const user = await loginUser({ email: state.email, password: state.password });
        if (context.root.$route.params && context.root.$route.params.slug) {
          triggerHubspot();
          createHubspot();
        }
        try {
          await onLogin(user!.accessToken);
        } catch (err) {
          console.log(err);
        }
        if (!props.isInvite) {
          if ($route.params.page) $router.push({ path: $route.params.page });
          else $router.push({ name: 'setup' });
        }
      } catch (err) {
        if (err.statusCode === 401)
          state.error = 'That email and password combination does not exist';
        else state.error = err;
      }
    };

    return {
      ...toRefs(state),
      ...toRefs(ui),
      login,
      triggerHubspot,
      toggleShowPassword,
      sendResetPasswordEmail
    };
  }
};
