export const INVITE_PAGES = {
  Affirm: {
    user: 'employer',
    password: 'affirm',
    video: 'cLQOfJJWeis'
  },

  'Alameda Chamber Of Commerce': {
    user: 'employer',
    password: 'alamedachamber',
    video: 'h4h2l84ZNLw'
  },

  'Alameda County': {
    user: 'employer',
    password: 'alamedacounty',
    video: '_eqGuDQpOE4'
  },

  'Alan AI': {
    user: 'employer',
    password: 'alan',
    video: 'edDmkzlc3nA'
  },

  'AP Racing': {
    user: 'employer',
    password: 'apracing',
    video: '5puXzjBn62Y'
  },

  BART: {
    user: 'employer',
    password: 'bart',
    video: 'W5JFjfKRLEg'
  },

  'City Of Antioch': {
    user: 'employer',
    password: 'antioch',
    video: 'FjnqWo17FW8'
  },

  'City Of San Leandro Engineering': {
    user: 'employer',
    password: 'sanleandro',
    video: 'c-mMXLwTpTg'
  },

  'Councilmember Holland White': {
    user: 'employer',
    password: 'holland',
    video: 'uGuq-lMUOL8'
  },

  'Councilmember Sara Lamnin': {
    user: 'employer',
    password: 'hayward',
    video: 'AzLA_CPXgVE'
  },

  Glydways: {
    user: 'employer',
    password: 'glydways',
    video: '4PxAlYaaOKI'
  },

  'James Gardner': {
    user: 'employer',
    password: 'gardner',
    video: 'df70W1HrjBI'
  },

  'Kengo Yoshii': {
    user: 'employer',
    password: 'kengo',
    video: 'c3VbyF3wRqo'
  },

  'Lawrence Berkeley National Lab': {
    user: 'employer',
    password: 'berkeleylab',
    video: ''
  },

  'Office Of Congresswoman Barbara Lee': {
    user: 'employer',
    password: 'barbaralee',
    video: 'LnI7bMDc2_I'
  },

  'One Workplace': {
    user: 'employer',
    password: 'oneworkplace',
    video: 'd4dXzlcXhNw'
  },

  'Phase Space': {
    user: 'employer',
    password: 'phasespace',
    video: 'Ppze1eQQJD0'
  },

  'Raconteur Animation': {
    user: 'employer',
    password: 'raconteur',
    video: 'zMon-0lIIbE'
  },

  'San Francisco Fire Department': {
    user: 'employer',
    password: 'sffd',
    video: 'a-eF7wXn5GU'
  },

  'San Mateo County Labs': {
    user: 'employer',
    password: 'smclabs',
    video: 'm3Bn1TxOv8s'
  },

  Seeed: {
    user: 'employer',
    password: 'seeed',
    video: 'ueObATSbs0k'
  },

  'Takeoff Point': {
    user: 'employer',
    password: 'takeoff',
    video: 'roxTlCTYkow'
  },

  // ROUND 1 SCHOOLS

  'Antioch High School': {
    user: 'school',
    password: 'antiochhigh',
    video: 'd5a4CINz_Ns'
  },

  'Basis Independent Fremont': {
    user: 'school',
    password: 'basis',
    video: 'DlvJuX0D26w'
  },

  'Berkeley Technology Academy': {
    user: 'school',
    password: 'bta',
    video: '9VoP6uPmODQ'
  },

  'Berkeley Unified School District': {
    user: 'school',
    password: 'busd',
    video: 'Sd4ykJny6eo'
  },

  'Carla Ng': {
    user: 'school',
    password: 'carla',
    video: 'pUzJ-OWTXHw'
  },

  'Cece Adams': {
    user: 'school',
    password: 'cece',
    video: 'qulvPCNo6jY'
  },

  'Daniel Spinka': {
    user: 'school',
    password: 'spinka',
    video: '85iRhdtjkAE'
  },

  'Design Tech High School': {
    user: 'school',
    password: 'dtech',
    video: 'HWffCteneCU'
  },

  'Eastside College Prep School': {
    user: 'school',
    password: 'eastside',
    video: 'Zmf2UVgzzwI'
  },

  'Emiliano Sanchez': {
    user: 'school',
    password: 'emiliano',
    video: 'mQ_K1TG-MaI'
  },

  'Fremont Unified School District': {
    user: 'school',
    password: 'fusd',
    video: 'dkXG7FouHRg'
  },

  'Google Code Next': {
    user: 'school',
    password: 'codenext',
    video: 'mAbYaERVy8I'
  },

  'Janevette Cole': {
    user: 'school',
    password: 'janevette',
    video: 'in8C1LwGMi4'
  },

  'Jefferson Union High School District': {
    user: 'school',
    password: 'juhsd',
    video: '__CXM3bbreQ'
  },

  KIPP: {
    user: 'school',
    password: 'kipp',
    video: '88-cI45-d30'
  },

  'Leadership Public Schools': {
    user: 'school',
    password: 'lps',
    video: 'M3s9Y-8-Ftw'
  },

  'Lehigh University': {
    user: 'school',
    password: 'lehigh',
    video: '6m1R5if22bo'
  },

  'Madison Park Academy': {
    user: 'school',
    password: 'mpa',
    video: 'fRh9Q0e3ECY'
  },

  'Marin County Office Of Education': {
    user: 'school',
    password: 'mcoe',
    video: 'LnfGrxU2hjQ'
  },

  'Menlo School': {
    user: 'school',
    password: 'menlo',
    video: 'xhXrnH7WR54'
  },

  'Mills High School': {
    user: 'school',
    password: 'mills',
    video: 'H82iAm_ezFI'
  },

  'Oakland Tech High School': {
    user: 'school',
    password: 'tech',
    video: 'xDQPjKDtL9M'
  },

  'Principal James Gray': {
    user: 'school',
    password: 'gray',
    video: 'XGd5fZHg2W0'
  },

  'San Leandro Unified School District': {
    user: 'school',
    password: 'slusd',
    video: 'Ucs8ARqlp4U'
  },

  'San Mateo County Office Of Ed': {
    user: 'school',
    password: 'smcoe',
    video: 'bxvH7hFCFU8'
  },

  'Sequoia Union High School District': {
    user: 'school',
    password: 'seq',
    video: 'Y3sGGkwEKD4'
  },

  'South San Francisco Unified School District': {
    user: 'school',
    password: 'ssfusd',
    video: 'W8W9WEKUIHU'
  },

  // ROUND 1 SPONSORS

  AFCEA: {
    user: 'sponsor',
    password: 'afcea',
    video: 'FUcM-lDFaj8'
  },

  'East Bay Leadership Council': {
    user: 'sponsor',
    password: 'eblc',
    video: 'lddMI6cf4qs'
  },

  'Oakland Workforce Development Board': {
    user: 'sponsor',
    password: 'owdb',
    video: 'B9QaGRIp7MY'
  },

  // ROUND 1 TEACHERS

  'Arroyo High School': {
    user: 'school',
    password: 'arroyo',
    video: '56dbFDK0VmA'
  },

  'Brian Hsieh': {
    user: 'teacher',
    password: 'hsieh',
    video: '8EsUy5B_PX0'
  },

  'Christina Charlton': {
    user: 'teacher',
    password: 'charlton',
    video: 'dEatXU-uJSo'
  },

  'Colegio Nueva Granada': {
    user: 'school',
    password: 'colegio',
    video: 'CrF0oRyvnx8'
  },

  'Darryl Starr': {
    user: 'teacher',
    password: 'starr',
    video: 'ZrH7RepuDxk'
  },

  'Eden Youth Family Center': {
    user: 'teacher',
    password: 'eyfc',
    video: 'JJBZeIeusZ8'
  },

  'Irvington High School': {
    user: 'school',
    password: 'irvington',
    video: 'Oa9ZaBUXer4'
  },

  'Jadie Sun': {
    user: 'teacher',
    password: 'sun',
    video: 'URBq2YIGzHc'
  },

  'Kam O Horo': {
    user: 'teacher',
    password: 'ohoro',
    video: '3oFAtyZF-2A'
  },

  'Kent Mc Cutcheon': {
    user: 'teacher',
    password: 'kent',
    video: '8Giu1Em6jxs'
  },

  'Kyra Wheaton': {
    user: 'teacher',
    password: 'wheaton',
    video: 'NtY-6_GyGoc'
  },

  'Lance Brookner': {
    user: 'teacher',
    password: 'brookner',
    video: 'gIreAGITWMo'
  },

  'Larry Lavendel': {
    user: 'teacher',
    password: 'lavendel',
    video: 'yDkqAbDqFkc'
  },

  'Marc Gordon': {
    user: 'teacher',
    password: 'gordon',
    video: 'ySKRTuuWTXY'
  },

  'Melissa Hale': {
    user: 'teacher',
    password: 'hale',
    video: 'o2d4ckYBwLI'
  },

  'Rebecca Lee': {
    user: 'teacher',
    password: 'lee',
    video: 'lVPGD3Whbvw'
  },

  'Rick Charles': {
    user: 'teacher',
    password: 'charles',
    video: 'StBqHggBQXg'
  },

  'Thalia Mc Neil Smith': {
    user: 'teacher',
    password: 'smith',
    video: 'Ckx_eDYOpfM'
  },

  'Yvie Raij': {
    user: 'teacher',
    password: 'raij',
    video: '45lRCdi88wA'
  },

  'West Contra Costa Unified': {
    user: 'school',
    password: 'wccusd',
    video: 'L6-wGa-Kkmk'
  },

  // ROUND 2222222222222222222 EMPLOYERS

  Bexi: {
    user: 'employer',
    password: 'roomies',
    video: 's9-0MRuL19M'
  },

  'Office Of Supervisor Nate Miley': {
    user: 'employer',
    password: 'miley',
    video: 'J-Vy7HTJzQE'
  },

  'Menlo Park Fire District': {
    user: 'employer',
    password: 'mpfd',
    video: 'on1EZIY5WTM'
  },

  'Orange Silicon Valley': {
    user: 'employer',
    password: 'orange',
    video: 'Eb8TDpSPjp8'
  },

  'Rising Sun Center For Opportunity': {
    user: 'employer',
    password: 'rising',
    video: 'OijHWsHUJcA'
  },

  NVDIA: {
    user: 'employer',
    password: 'autonomous',
    video: 'DvjySKDjQ7g'
  },

  'Google Wing': {
    user: 'employer',
    password: 'delivery',
    video: '3dac6tS3zIk'
  },

  // ROUND 2222222222222222222 SCHOOLS

  'Hillsdale High School': {
    user: 'school',
    password: 'hillsdale',
    video: 'GV6dvhT0Ql0'
  },

  'Dane Lancaster': {
    user: 'school',
    password: 'dane',
    video: 'lGalBo5EoPo'
  },

  'Camp Sweeney': {
    user: 'school',
    password: 'sweeney',
    video: '4Dh9aads3hg'
  },

  'TIDE Academy': {
    user: 'school',
    password: 'tide',
    video: 'KcUBCmjCPpo'
  },

  'California Crosspoint Academy': {
    user: 'school',
    password: 'crosspoint',
    video: 'h-hlrP0V6Gg'
  },

  'Emery High School': {
    user: 'school',
    password: 'emeryhigh',
    video: 'kJMbhGFFFRo'
  },

  // ROUND 2222222222222222222 TEACHERS

  'Erin Susoev': {
    user: 'teacher',
    password: 'erin',
    video: 'c4pLPjfLKf0'
  },

  'John Lavine': {
    user: 'teacher',
    password: 'lavine',
    video: 'G2fhr3sSQj4'
  },

  'Royal Sunset High School': {
    user: 'teacher',
    password: 'sunset',
    video: 'kkoLT7R7dzI'
  },

  'Danny Tan': {
    user: 'teacher',
    password: 'danny',
    video: 'Z9kfZIa5XOg'
  },

  'Nina Arnberg': {
    user: 'teacher',
    password: 'nina',
    video: 'hEX5B71-wPg'
  },

  'Ramal Llamar': {
    user: 'teacher',
    password: 'llamar',
    video: 'mtG_5BpKII4'
  },

  'Rebekah Kotlar': {
    user: 'teacher',
    password: 'kotlar',
    video: 'ZFyUrrKsrFI'
  },

  // ROUND 2222222222222222222.5

  'Diablo Valley College': {
    user: 'school',
    password: 'dvc',
    video: 'mUpNfTyPnHg'
  },

  'Oakland Tech PTSA': {
    user: 'school',
    password: 'internships',
    video: 'o2lIs6pDKw8'
  },

  'Oakland Tech Students': {
    user: 'student',
    password: 'getinternships',
    video: 'vAYHQc_9k9Y'
  },

  // ROUND 3 - ONLY EMPLOYERS AND SPONSORS

  'Marin Clean Energy': {
    user: 'sponsor',
    password: 'action',
    video: 'SGkrd18YyQY'
  },

  AnaSpec: {
    user: 'employer',
    password: 'biotech',
    video: 'dm0pWI7De-M'
  },

  'Shannon Leigh': {
    user: 'employer',
    password: 'designers',
    video: '8WoxxdP6y-c'
  },

  'Common Networks': {
    user: 'employer',
    password: 'digitalequity',
    video: ''
  },

  'Tech Exchange': {
    user: 'employer',
    password: 'digitalequity',
    video: 'xbskOnz4uuo'
  },

  'Fab City Oakland': {
    user: 'employer',
    password: 'distribute',
    video: 'bRw3FJucb64'
  },

  'City Of San Leandro Sustainability': {
    user: 'employer',
    password: 'ecosystem',
    video: 'TbGmKbVbYiI'
  },

  'City Of East Palo Alto': {
    user: 'employer',
    password: 'epa',
    video: '/_6Nmh0eoawY'
  },

  NIST: {
    user: 'employer',
    password: 'forensics',
    video: 'IV9FDJO_UU4'
  },

  'Alameda Health System': {
    user: 'employer',
    password: 'futureofhealth',
    video: 'o2ge_5-a7mQ'
  },

  'AC Transit': {
    user: 'employer',
    password: 'futuretransit',
    video: 'wgZyg_uQOwU'
  },

  'San Leandro Library': {
    user: 'employer',
    password: 'goingdigital',
    video: '9HD1FmFHZVA'
  },

  Climatec: {
    user: 'sponsor',
    password: 'impact',
    video: 'HFmu0MjY2kk'
  },

  EBMUD: {
    user: 'employer',
    password: 'innovatewater',
    video: 'I6L74gjAoxU'
  },

  Typeform: {
    user: 'employer',
    password: 'interact',
    video: 'rlQNqOyvOPk'
  },

  'Tom Eppenberger': {
    user: 'employer',
    password: 'kp',
    video: 'TMhKYdmK_ZU'
  },

  Ivaldi: {
    user: 'employer',
    password: 'mfg',
    video: 'tvFzPQMIzoM'
  },

  'Contra Costa County Sheriffs': {
    user: 'employer',
    password: 'nextgen',
    video: 'WMQsIs7XyK0'
  },

  'City Of Pittsburg': {
    user: 'employer',
    password: 'pittsburg',
    video: 'pittsburg'
  },

  'City Of San Leandro Public Works': {
    user: 'employer',
    password: 'publicworks',
    video: 'eD_We1UaT2k'
  },

  'Currie Lee MD': {
    user: 'employer',
    password: 'raiders',
    video: 'wZFS-RbnNaY'
  },

  'Carlton Senior Living': {
    user: 'employer',
    password: 'seniors',
    video: 'MvKrRykRMWA'
  },

  FLIR: {
    user: 'employer',
    password: 'sensors',
    video: 'Ozi2vMOkq_s'
  },

  'City Of Hayward': {
    user: 'employer',
    password: 'smartcity',
    video: 'FXxzMd-W5DU'
  },

  'Dew Mobility': {
    user: 'employer',
    password: 'smartcity',
    video: 'lenIt3Mv6Lc'
  },

  'Port Of Oakland': {
    user: 'employer',
    password: 'theport',
    video: 'C0bH-XI57h0'
  },

  'Vantage Robotics': {
    user: 'employer',
    password: 'uav',
    video: 'sOVzFTLHySc'
  },

  // ROUND 4
  'Amethod Public Schools': {
    user: 'school',
    password: 'oakland',
    video: 'PnadTDEXZkE'
  },

  'Averroes High School': {
    user: 'school',
    password: 'averroes',
    video: 'NhvLfeNUpwc'
  },

  'Berkeley High School': {
    user: 'school',
    password: 'internships',
    video: 'Roj6fPRwXxQ'
  },

  'Cabrillo Unified School District': {
    user: 'school',
    password: 'internships',
    video: 'OCKgn6mZA5U'
  },

  'Clayton Valley Charter High School': {
    user: 'school',
    password: 'internships',
    video: 'CznYbrlX1xE'
  },

  'Code For Fun': {
    user: 'school',
    password: 'coders',
    video: '8QzMXsRdk3M'
  },

  'Emery Unified School District': {
    user: 'school',
    password: 'wins',
    video: 'YetY9RP4sq0'
  },

  'Envision Schools': {
    user: 'school',
    password: 'internships',
    video: 'GWui3UBcFfk'
  },

  'Brenkwitz High School': {
    user: 'school',
    password: 'transform',
    video: 'p1vVeASpNyA'
  },

  'Hayward Unified School District': {
    user: 'school',
    password: 'grow',
    video: 'OYU8xNJFYKo'
  },

  'Thornton High School': {
    user: 'school',
    password: 'internships',
    video: '7s0ZO1SGJzg'
  },

  'Latitude High School': {
    user: 'school',
    password: 'internships',
    video: '47x5gHoLv6Q'
  },

  'Lighthouse Charter High School': {
    user: 'school',
    password: 'internships',
    video: '9t2hMmBdZR8'
  },

  'Mission Valley ROP': {
    user: 'school',
    password: 'internships',
    video: 'wZByC-9JFhA'
  },

  'Moreau Catholic High School': {
    user: 'school',
    password: 'internships',
    video: '_eVRl-g1-9A'
  },

  'Mount Eden High School': {
    user: 'school',
    password: 'internships',
    video: 'zigZR0bcBYU'
  },

  'MW Academy': {
    user: 'school',
    password: 'internships',
    video: '3t_jSSkRuBE'
  },

  'Novato High School': {
    user: 'school',
    password: 'internships',
    video: 'sogSf6j8Ngk'
  },

  Civicorps: {
    user: 'school',
    password: 'internships',
    video: 'lUZbUIov1U4'
  },

  'Oakland Unified School District': {
    user: 'school',
    password: 'scalingwbl',
    video: 'D6OyxPExEwM'
  },

  'Pittsburg Unified School District': {
    user: 'school',
    password: 'round2',
    video: 'tmMIT789ZSc'
  },

  'Quarry Lane School': {
    user: 'school',
    password: 'internships',
    video: 'klA4D8L6Dr0'
  },

  'San Francisco Unified School District': {
    user: 'school',
    password: 'scalingwbl',
    video: '44bvakCmjA4'
  },

  'Olga Pineda': {
    user: 'school',
    password: 'thanks',
    video: 'bB8LotCdbvU'
  },

  'Nancy Kane': {
    user: 'school',
    password: 'internships',
    video: 'MoupGpegTBs'
  },

  'San Mateo High School': {
    user: 'school',
    password: 'internships',
    video: 'A_CV5PRua9E'
  },

  'Aragon High School': {
    user: 'school',
    password: 'wbl',
    video: 'wJ3atRlgWsE'
  },

  'San Mateo Union High School District': {
    user: 'school',
    password: 'smuhsd',
    video: 'CpX4IBbhxVU'
  },

  'Alton Lee': {
    user: 'teacher',
    password: 'alton',
    video: 't57JJ3Db8I8'
  },

  'Carlmont High School': {
    user: 'school',
    password: 'internships',
    video: 'Gn6rdAYK4qA'
  },

  'Summit Public Schools': {
    user: 'school',
    password: 'internships',
    video: 'X95d3eaL6l0'
  },

  'Dawn Fregosa': {
    user: 'teacher',
    password: 'og',
    video: 'p38ZUEwc2GQ'
  },

  // ROUND 5 (45 qty)

  'Chris Rose': {
    user: 'teacher',
    password: 'rose',
    video: 'O-mLGTgrmco'
  },

  'Jeff Adkins': {
    user: 'teacher',
    password: 'adkins',
    video: 'jqpiRT8w16c'
  },

  'Terry Palmer': {
    user: 'teacher',
    password: 'palmer',
    video: 'KSCl_QETjtM'
  },

  'Jessica Govoni': {
    user: 'teacher',
    password: 'govoni',
    video: 'ATwgctjevrw'
  },

  'Courtney Peterson': {
    user: 'teacher',
    password: 'peterson',
    video: 't3fB_5BUYAM'
  },

  'Joseph Centoni': {
    user: 'teacher',
    password: 'centoni',
    video: 'ljmMCg2IniY'
  },

  'Hannah Carney': {
    user: 'teacher',
    password: 'carney',
    video: '1mxu5Z231ls'
  },

  'The Andersons': {
    user: 'teacher',
    password: 'internships',
    video: '32ttTSBIxmQ'
  },

  'Michelle Kerr': {
    user: 'teacher',
    password: 'kerr',
    video: '7Q6obkSmzis'
  },

  'Kennedy High School': {
    user: 'teacher',
    password: 'internships',
    video: '0OkVzooXoaE'
  },

  'Hunter Lower': {
    user: 'teacher',
    password: 'lower',
    video: '6BsQp9MyB-E'
  },

  'Deborah Jackson': {
    user: 'teacher',
    password: 'jackson',
    video: 'keV1PJ8Livs'
  },

  'David Gurley': {
    user: 'teacher',
    password: 'gurley',
    video: 'MhTIV5Kc3Sc'
  },

  'Joshua Moran': {
    user: 'teacher',
    password: 'moran',
    video: 'AXamg6RvEqw'
  },

  'TK Pryor Knell': {
    user: 'teacher',
    password: 'tk',
    video: 'My5iC5Q0k7w'
  },

  'Richard Baugh': {
    user: 'teacher',
    password: 'baugh',
    video: 'Zz1k4puaoV4'
  },

  'Michael Rodriguez': {
    user: 'teacher',
    password: 'rodriguez',
    video: 'r4_cBinCWKg'
  },

  'Edward Lopez': {
    user: 'teacher',
    password: 'lopez',
    video: '5PlPqNdB4pI'
  },

  'Kirk Sands': {
    user: 'teacher',
    password: 'sands',
    video: 'HjPO2n4M6sc'
  },

  'Steve Walker': {
    user: 'teacher',
    password: 'walker',
    video: '1GWZ2JU75AM'
  },

  'Rachel Chou': {
    user: 'teacher',
    password: 'chou',
    video: 'oFns1QZkYK8'
  },

  'Matt Redmond': {
    user: 'teacher',
    password: 'redmond',
    video: 'QTdDa0RZSSg'
  },

  'Christina Ong': {
    user: 'teacher',
    password: 'ong',
    video: '0e5SRPdfg7s'
  },

  'Jasmene Miranda': {
    user: 'teacher',
    password: 'miranda',
    video: 'FNthbpKIxfo'
  },

  'Chloe Erskine': {
    user: 'teacher',
    password: 'erskine',
    video: 'IW9fSUSf2F8'
  },

  'James Shapiro': {
    user: 'teacher',
    password: 'shapiro',
    video: 'UQOlZx7oIz8'
  },

  'Meghan Johnston': {
    user: 'teacher',
    password: 'johnston',
    video: 'SM9rWti4Isk'
  },

  'Anthony Pena': {
    user: 'teacher',
    password: 'pena',
    video: 'DjJq-d03EQ0'
  },

  'Hank Machtay': {
    user: 'teacher',
    password: 'machtay',
    video: 'IXt_vYkynK0'
  },

  'Holger Michaelis': {
    user: 'teacher',
    password: 'michaelis',
    video: 'nDndBhNFyNE'
  },

  'George Lee': {
    user: 'teacher',
    password: 'lee',
    video: 'ZaETGWdRZhM'
  },

  'Eddie Nava': {
    user: 'teacher',
    password: 'nava',
    video: 'YLIGmPJMuoU'
  },

  'Tony Farley': {
    user: 'teacher',
    password: 'farley',
    video: 'L_WK--FM3Kw'
  },

  'Laura Jagroop': {
    user: 'teacher',
    password: 'jagroop',
    video: '6naxan3o9Bk'
  },

  'Juanita Abundiz Henderson': {
    user: 'teacher',
    password: 'abundiz',
    video: 'GzGCkJSs__4'
  },

  'Deborah Reinerio': {
    user: 'teacher',
    password: 'reinerio',
    video: 'p4UiNeds2wU'
  },

  'Tom Grace': {
    user: 'teacher',
    password: 'grace',
    video: 'puemZUC4eOk'
  },

  'Chris Rubin': {
    user: 'teacher',
    password: 'rubin',
    video: 'e2-LV0SEDWY'
  },

  'John Rowe': {
    user: 'teacher',
    password: 'rowe',
    video: 'WQxTVrilulg'
  },

  'Shannon Couch': {
    user: 'teacher',
    password: 'couch',
    video: '2BJ0dYeWieY'
  },

  'Aaron Boyd': {
    user: 'teacher',
    password: 'boyd',
    video: '7872-MXbjVQ'
  },

  // ROUND 6

  'Boys And Girls Club San Leandro': {
    user: 'school',
    password: 'internships',
    video: 'lmbNLmKs8fM'
  },

  'Brian Ortiz Classroom': {
    user: 'student',
    password: 'internships',
    video: '9AmlBLybWsg'
  },
  'Shop Talk': {
    user: 'teacher',
    password: 'internships',
    video: 'hZ2QUtc0S_c'
  },
  'East Bay Regional Parks District Archive': {
    user: 'employer',
    password: 'ebrpd',
    video: 'r9mRysvPHwg'
  },
  'Arroyo Parents': {
    user: 'parent',
    password: 'internships',
    video: 'fOYapMYCfQo'
  },
  'Fremont Moms': {
    user: 'parent',
    password: 'internships',
    video: 'kTavoCXHYFM'
  },
  AAMAA: {
    user: 'employer',
    password: 'localimpact',
    video: '1K_FGIW0kYk'
  },
  Bill: {
    user: 'employer',
    password: 'fintech',
    video: 'YwsUz-COPsI'
  },
  // ROUND 7
  IBM: {
    user: 'employer',
    password: 'impact',
    video: 'XzRR2FC0ltw'
  },
  Boostalgo: {
    user: 'employer',
    password: 'impact',
    video: 'Givf4L_T5sw'
  },
  UCT: {
    user: 'employer',
    password: 'impact',
    video: 'YIPJuiXeqE0'
  },
  'Lam Research': {
    user: 'employer',
    password: 'impact',
    video: 'R2eG-cB_pm8'
  },
  'Palo Alto Networks': {
    user: 'employer',
    password: 'impact',
    video: 'oNXyBlLByE8'
  },
  Visa: {
    user: 'employer',
    password: 'impact',
    video: 'vDCYbf08GwY'
  },
  Droisys: {
    user: 'employer',
    password: 'impact',
    video: 'bbRtFRyUIxw'
  },
  'Sutter Health': {
    user: 'employer',
    password: 'impact',
    video: 'FWbhou-EQKM'
  },
  'Cisco Global Infrastructure Services': {
    user: 'employer',
    password: 'impact',
    video: 'qAcz1kFtFiE'
  },
  Workday: {
    user: 'employer',
    password: 'impact',
    video: 'ZS6VmOhoMFE'
  },
  Uber: {
    user: 'employer',
    password: 'impact',
    video: 'gd_OvNyj6bI'
  },
  Cognizant: {
    user: 'employer',
    password: 'impact',
    video: 'jBpKtmO57gg'
  },
  'Amazon Prime Video': {
    user: 'employer',
    password: 'impact',
    video: 'osGkNzhgPQ4'
  },
  Salesforce: {
    user: 'employer',
    password: 'impact',
    video: '3FuKcCfHI4I'
  },
  'Cisco Corporate Development Technology': {
    user: 'employer',
    password: 'impact',
    video: 'lb8J5wVkW-I'
  },
  KLA: {
    user: 'employer',
    password: 'impact',
    video: '_e3tKfeNfVs'
  },

  // ROUND 8
  Dyndrite: {
    user: 'employer',
    password: 'digitalmfg',
    video: 'FTfKosemOmc'
  },
  'Urban Bloc': {
    user: 'employer',
    password: 'outdoor',
    video: '2_eqsQI-IPw'
  },

  'Velodyne Lidar': {
    user: 'employer',
    password: 'av',
    video: 'N6VgXWYvJXg'
  },

  'Strategy Of Things': {
    user: 'employer',
    password: 'iot',
    video: 'HjZrIm3Wjnw'
  },

  Picterra: {
    user: 'employer',
    password: 'ai',
    video: 'ml8-OBbFNy0'
  },

  Litterati: {
    user: 'employer',
    password: 'smarttrash',
    video: 'QstlJilqDd0'
  },

  Agritecture: {
    user: 'employer',
    password: 'iot',
    video: 'EHGubw2N68Q'
  },

  'Scandic Springs': {
    user: 'employer',
    password: 'mfg',
    video: 'xD2aHpi-nlA'
  },

  'Applied Fusion': {
    user: 'employer',
    password: 'mfg',
    video: 'qh_xRleVziM'
  },

  Fathom: {
    user: 'employer',
    password: 'nextlevel',
    video: 'lJ1ELFDK3Lc'
  },

  Matson: {
    user: 'employer',
    password: 'logistics',
    video: 'w-kIJ1WrA6c'
  },

  'Surplus Service': {
    user: 'employer',
    password: 'upcycle',
    video: '4ptWSYYHJ64'
  },

  'George Mark Childrens House': {
    user: 'employer',
    password: 'empathy',
    video: 'AuODwtpUjnc'
  },

  'East Bay Community Energy': {
    user: 'employer',
    password: 'mobilization',
    video: 'KkHegb3gmI0'
  },

  'Contra Costa Transportation Authority': {
    user: 'employer',
    password: 'transport',
    video: '-_MKVhDStrI'
  },

  'Prime Roots': {
    user: 'employer',
    password: 'shrooms',
    video: 'sHzbvsYnC6E'
  },

  'Symbio Robotics': {
    user: 'employer',
    password: 'mfg',
    video: 'V9u5pyPiJa0'
  },

  '6x7': {
    user: 'employer',
    password: 'sales',
    video: 'Ou9UaBdR-k0'
  },

  // ROUND 9
  'Councilmember Victor Aguilar': {
    user: 'employer',
    password: 'sideprojects',
    video: 'EACJazwBJVA'
  },

  'Congressman Eric Swalwell': {
    user: 'employer',
    password: 'sideprojects',
    video: 'cOfSqsCmLmQ'
  },

  'Assembly Member Rob Bonta': {
    user: 'employer',
    password: 'sideprojects',
    video: 'jwkhp7fAsCc'
  },

  Bayer: {
    user: 'employer',
    password: 'biotech',
    video: 'He1owAKz0L8'
  },

  'Chris Chin': {
    user: 'employer',
    password: 'immersive',
    video: 'CfzciwdpJes'
  },

  Manex: {
    user: 'employer',
    password: 'mfg',
    video: '2WVjgMN5ipw'
  },

  'San Leandro Police Department': {
    user: 'employer',
    password: 'resilience',
    video: '8oCDIoPW_3Q'
  },

  'Mayor Pauline Cutter': {
    user: 'employer',
    password: 'sideprojects',
    video: '9chm5NS7tf0'
  },

  'Councilmember Deborah Cox': {
    user: 'employer',
    password: 'sideprojects',
    video: '-dxo1mLhEGw'
  },

  'Councilmember Pete Ballew': {
    user: 'employer',
    password: 'sideprojects',
    video: 'aiCdO3ADsnE'
  },

  'Councilmember Corina Lopez': {
    user: 'employer',
    password: 'sideprojects',
    video: 'NTHst6NUnag'
  },

  // ROUND 10

  'ACOE SWP Partners': {
    user: 'school',
    password: 'internships',
    video: 'PQlA1kPSYBU'
  },

  'Adaptable Security': {
    user: 'employer',
    password: 'cybersecurity',
    video: '-mNNcjtyGC0'
  },

  'City Of San Leandro IT': {
    user: 'employer',
    password: 'tech',
    video: 'JZdbgoITGso'
  },

  'Zocalo Coffee': {
    user: 'employer',
    password: 'roast',
    video: 'LJy3n7uuz7U'
  },

  'UCSF CSEO': {
    user: 'school',
    password: 'internships',
    video: 'mbw50ewGOQw'
  },

  'Amazon Managed Blockchain': {
    user: 'employer',
    password: 'blockchain',
    video: '2Fq3yFFOdVQ'
  },

  'Alameda Unified School District': {
    user: 'school',
    password: 'internships',
    video: 'q4FqoQMGFgk'
  },

  'FUJIFILM Irvine Scientific': {
    user: 'employer',
    password: 'biotech',
    video: 'Hb7WCGmYnkg'
  },

  'Main Street Property Services': {
    user: 'employer',
    password: 'retailers',
    video: '4WrpuWprWbI'
  },

  'Herrick Fang': {
    user: 'teacher',
    password: '23',
    video: 'tXHmGohjb-E'
  },

  // ROUND 11 HODGEPODGE

  'Wild Earth': {
    user: 'employer',
    password: 'biotech',
    video: 'H29U7ColrwA'
  },

  'Pinx Catering': {
    user: 'employer',
    password: 'rebuild',
    video: '88FaCyLuDFI'
  },

  'True West Ventures': {
    user: 'employer',
    password: 'foodie',
    video: 'UuRAATKYvuo'
  },

  'Fremont Fire Department': {
    user: 'employer',
    password: 'mutualaid',
    video: 'FcqbTsLnC5c'
  },

  'College Of Alameda': {
    user: 'school',
    password: 'internships',
    video: 'QXix72SKtbA'
  },

  'Mark Martin': {
    user: 'school',
    password: 'updateformark',
    video: 'SDVk7Svie5o'
  },

  'BATS Members': {
    user: 'school',
    password: 'internships',
    video: '_Td6mG6wFsQ'
  },

  'Berkeley High School Teachers': {
    user: 'teacher',
    password: 'internships',
    video: 'csc7w54Gu5k'
  },

  // ANDREW DOOLITTLE

  'Andrew Doolittle': {
    user: 'teacher',
    password: 'doolittle',
    video: 'ioeLlpe07wA'
  },

  // ROUND 12

  Cognixion: {
    user: 'employer',
    password: 'ar',
    video: 'MxMWCet9dfA'
  },

  'Turner Construction': {
    user: 'employer',
    password: 'shop',
    video: '39npDknYw6s'
  },

  'Enel X': {
    user: 'employer',
    password: 'community',
    video: '_LEx5cgXjVo'
  },

  'Evolve Manufacturing': {
    user: 'employer',
    password: 'mfg',
    video: 'PcSvvAwx3jE'
  },

  Notion: {
    user: 'employer',
    password: 'nextgenusers',
    video: 'rklfTZxgo8I'
  },

  'House Kombucha': {
    user: 'employer',
    password: 'hearts',
    video: '-NgD62nDu2A'
  },

  // ROUND 13

  'Unpossible Cuts': {
    user: 'employer',
    password: 'laser',
    video: 'Kx6JzTcSgZc'
  },

  'Spring Energy': {
    user: 'employer',
    password: 'superfood',
    video: 'ngRd4u0bX20'
  },

  'Proyecto Diaz': {
    user: 'employer',
    password: 'beans',
    video: '-X1APeYwsk8'
  },

  Forust: {
    user: 'employer',
    password: 'fdm',
    video: 'JQ2Mz07ql4c'
  },

  'Purely Drinks': {
    user: 'employer',
    password: 'vinegar',
    video: 'oJofgFfNBVs'
  },

  '3E Design': {
    user: 'employer',
    password: 'adu',
    video: 'eER8vdkdD4s'
  },

  Gantri: {
    user: 'employer',
    password: 'digitalmfg',
    video: 'vrjUwr-TRrM'
  },

  'Basin Research': {
    user: 'employer',
    password: 'dig',
    video: 'aMWUc2zLSIs'
  },

  'Magic Fuel Games': {
    user: 'employer',
    password: 'gaming',
    video: 'kx3a2fg9wfQ'
  },

  'Cyclotron Road': {
    user: 'employer',
    password: 'lbnl',
    video: 'EKpLc4KCfbw'
  },

  Airspace: {
    user: 'employer',
    password: 'drones',
    video: 'GvFTOF2Nq8w'
  },

  Sculpteo: {
    user: 'employer',
    password: '3dp',
    video: 'OQvgr0vwphM'
  },

  'Artemys Foods': {
    user: 'employer',
    password: 'burgers',
    video: 'vz--lFG5P2I'
  },

  Revivn: {
    user: 'employer',
    password: 'community',
    video: 'KjTyinleUkc'
  },

  TeckQuest: {
    user: 'employer',
    password: 'storage',
    video: 'jgGrr3aJcLY'
  },

  Anzode: {
    user: 'employer',
    password: 'zinc',
    video: 'fcJDoq941VQ'
  },

  'Coreshell Technologies': {
    user: 'employer',
    password: 'magiclayer',
    video: 'udWZmdSDmM'
  },

  'FreeWire Technologies': {
    user: 'employer',
    password: 'ev',
    video: 'UhgT75k1PLk'
  },

  Fochon: {
    user: 'employer',
    password: 'therapy',
    video: 'EvEtadr7dQA'
  },

  'Aralez Bio': {
    user: 'employer',
    password: 'enzymes',
    video: 'B_UyPTnDeBk'
  },

  'HJ Science And Technology': {
    user: 'employer',
    password: 'prototyping',
    video: '-sIzKcBFDLs'
  },

  'Ensigna Biosystems': {
    user: 'employer',
    password: 'histopathology',
    video: 'R9v2wfwzttg'
  },

  'Pace Analytical': {
    user: 'employer',
    password: 'impact',
    video: 'p0LEL1WsiCY'
  },

  Sartorius: {
    user: 'employer',
    password: 'interns',
    video: 'aLs4E437uIg'
  },

  'Cinder Bio': {
    user: 'employer',
    password: 'interns',
    video: '333W-Bgc3xU'
  },

  'Tierra Biosciences': {
    user: 'employer',
    password: 'interns',
    video: 'BWHU5mkBkz4'
  },

  Geltor: {
    user: 'employer',
    password: 'ingredient',
    video: 'S0EY17EmBQk'
  },
  'Quarry Lane School Parents And Students': {
    user: 'parent',
    password: 'internships',
    video: 'BR0LRa2erB0'
  },

  // ROUND 14
  Zymergen: {
    user: 'employer',
    password: 'organisms',
    video: '1etke56R0AU'
  },
  Grifols: {
    user: 'employer',
    password: 'internships',
    video: 'W0TLVeEyCr4'
  },
  'City Of Emeryville': {
    user: 'employer',
    password: 'localtalent',
    video: 'FqtfYn9Dzb0'
  },

  'AM Bay Area': {
    user: 'employer',
    password: 'workforce',
    video: 'jnHbsEENF_Q'
  },

  'Ramar Foods': {
    user: 'employer',
    password: 'lumpia',
    video: 'X2TdyFIe2VA'
  },

  'Vacuum Process Engineering': {
    user: 'employer',
    password: 'innovation',
    video: 'S-uBULC2R7s'
  },
  // ROUND 15

  'Ohlone College Instructors': {
    user: 'teacher',
    password: 'engineers',
    video: 'r0dvf-w0Mtg'
  },
  'Engie Services US': {
    user: 'employer',
    password: 'stem',
    video: 'HgW97Y8U9aQ'
  },
  'Lao Family Community Development': {
    user: 'employer',
    password: 'digital',
    video: 'yDwtV7EN-uQ'
  },
  Torani: {
    user: 'employer',
    password: 'impact',
    video: '31lELSlY7LU'
  },

  // ROUND 16
  Adobe: {
    user: 'employer',
    password: 'design',
    video: '-UF3rg6xEyw'
  },
  'Alder Graduate School Of Education': {
    user: 'employer',
    password: 'handson',
    video: 'OW8xcZhjA4s'
  },
  '440 Creates': {
    user: 'employer',
    password: 'design',
    video: 'kP0FwsqAHoU'
  },
  'Councilmember Bryan Azevedo': {
    user: 'employer',
    password: 'aides',
    video: '9RPd65Iv21U'
  },
  'Councilmember Fred Simon': {
    user: 'employer',
    password: 'aides',
    video: 'L8DSXzZP2vQ'
  },
  'City Manager Fran Robustelli': {
    user: 'employer',
    password: 'intro',
    video: 'tN0xEf4JhW4'
  },

  // BAYWORK - ROUND 17

  'North San Mateo County Sanitation District': {
    user: 'employer',
    password: 'futureofwater',
    video: '_tAmNGesjFs'
  },

  'Las Gallinas Valley Sanitary District': {
    user: 'employer',
    password: 'futureofwater',
    video: '_tAmNGesjFs'
  },

  'Novato Sanitary District': {
    user: 'employer',
    password: 'futureofwater',
    video: '_tAmNGesjFs'
  },

  'Ross Valley Sanitary District': {
    user: 'employer',
    password: 'futureofwater',
    video: '_tAmNGesjFs'
  },

  'Tamalpais Community Services District': {
    user: 'employer',
    password: 'futureofwater',
    video: '_tAmNGesjFs'
  },

  'West Valley Sanitation District': {
    user: 'employer',
    password: 'futureofwater',
    video: '_tAmNGesjFs'
  },

  'Fairfield Suisun Sewer District': {
    user: 'employer',
    password: 'futureofwater',
    video: '_tAmNGesjFs'
  },

  'Napa Sanitation District': {
    user: 'employer',
    password: 'futureofwater',
    video: '_tAmNGesjFs'
  },

  'Oro Loma Sanitary District': {
    user: 'employer',
    password: 'futureofwater',
    video: '_tAmNGesjFs'
  },

  'Delta Diablo Sanitation District Archive': {
    user: 'employer',
    password: 'futureofwater',
    video: '_tAmNGesjFs'
  },

  'North Marin Water District': {
    user: 'employer',
    password: 'futureofwater',
    video: '_tAmNGesjFs'
  },

  'Silicon Valley Clean Water': {
    user: 'employer',
    password: 'futureofwater',
    video: '_tAmNGesjFs'
  },

  'South San Francisco San Bruno Wastewater Treatment Plant': {
    user: 'employer',
    password: 'futureofwater',
    video: '_tAmNGesjFs'
  },

  'City Of San Jose Water Utility': {
    user: 'employer',
    password: 'futureofwater',
    video: '_tAmNGesjFs'
  },

  'City Of Sunnyvale Water Utility': {
    user: 'employer',
    password: 'futureofwater',
    video: '_tAmNGesjFs'
  },

  'Sonoma Water': {
    user: 'employer',
    password: 'futureofwater',
    video: '_tAmNGesjFs'
  },

  'Zone 7 Water Agency': {
    user: 'employer',
    password: 'futureofwater',
    video: '_tAmNGesjFs'
  },

  'Central Contra Costa Sanitary District': {
    user: 'employer',
    password: 'futureofwater',
    video: '_tAmNGesjFs'
  },

  'Alameda County Water District': {
    user: 'employer',
    password: 'futureofwater',
    video: '_tAmNGesjFs'
  },

  'Dublin San Ramon Services District': {
    user: 'employer',
    password: 'futureofwater',
    video: '_tAmNGesjFs'
  },

  'Union Sanitary District': {
    user: 'employer',
    password: 'futureofwater',
    video: '_tAmNGesjFs'
  },

  'Marin Municipal Water District': {
    user: 'employer',
    password: 'futureofwater',
    video: '_tAmNGesjFs'
  },

  'San Jose Water Company': {
    user: 'employer',
    password: 'futureofwater',
    video: '_tAmNGesjFs'
  },

  'San Francisco Public Utilities Commission': {
    user: 'employer',
    password: 'futureofwater',
    video: '_tAmNGesjFs'
  },

  'Valley Water': {
    user: 'employer',
    password: 'futureofwater',
    video: '_tAmNGesjFs'
  },

  'City Of Pleasanton Water Utility': {
    user: 'employer',
    password: 'futureofwater',
    video: '3lj2DDafE49'
  },

  'City Of Pittsburg Water Utility': {
    user: 'employer',
    password: 'futureofwater',
    video: '3lj2DDafE49'
  },

  'City Of Pacifica Water Utility': {
    user: 'employer',
    password: 'futureofwater',
    video: '3lj2DDafE49'
  },

  'City Of Redwood City Water Utility': {
    user: 'employer',
    password: 'futureofwater',
    video: '3lj2DDafE49'
  },

  'Town Of Hillsborough Water Utility': {
    user: 'employer',
    password: 'futureofwater',
    video: '3lj2DDafE49'
  },

  'City Of Morgan Hill Corporation Yard': {
    user: 'employer',
    password: 'futureofwater',
    video: '3lj2DDafE49'
  },

  'City Of Gilroy Water Utility': {
    user: 'employer',
    password: 'futureofwater',
    video: '3lj2DDafE49'
  },

  'Town Of Windsor Water Utility': {
    user: 'employer',
    password: 'futureofwater',
    video: '3lj2DDafE49'
  },

  'Castro Valley Sanitary District': {
    user: 'employer',
    password: 'futureofwater',
    video: '3lj2DDafE49'
  },

  'City Of Roseville Corporation Yard': {
    user: 'employer',
    password: 'futureofwater',
    video: '3lj2DDafE49'
  },

  'City Of Hayward Water Utility': {
    user: 'employer',
    password: 'futureofwater',
    video: '3lj2DDafE49'
  },

  'City Of Livermore Water Utility': {
    user: 'employer',
    password: 'futureofwater',
    video: '3lj2DDafE49'
  },
  'City Of Napa Water Utility': {
    user: 'employer',
    password: 'futureofwater',
    video: '3lj2DDafE49'
  },
  Everimpact: {
    user: 'employer',
    password: 'uscities',
    video: '3WO05iMTP7M'
  },

  // CASTRO VALLEY MARKETPLACE

  'Akemi Sushi': {
    user: 'employer',
    password: 'fishfans',
    video: 'Uix9R4ldhi4'
  },

  'Amphora Nueva': {
    user: 'employer',
    password: 'oils',
    video: '2YgZKWfKUxo'
  },

  'Barons Quality Meats': {
    user: 'employer',
    password: 'ghostchile',
    video: 'B2Xg9qfzy90'
  },

  'Birdie Bakery': {
    user: 'employer',
    password: 'treats',
    video: 'x6IFRb4NusM'
  },

  'Cannery Kitchen And Tap': {
    user: 'employer',
    password: 'garlicfries',
    video: 'eg2gTVCP32E'
  },

  'Castro Valley Natural Grocery': {
    user: 'employer',
    password: 'wellbeing',
    video: 'bEwmFxdP9W0'
  },

  'Cordial Cellar And Lounge': {
    user: 'employer',
    password: 'bottles',
    video: 'WMJ1TYKUPFU'
  },

  'Farm Curious': {
    user: 'employer',
    password: 'cheesey',
    video: '4n1cwcuLOd0'
  },

  'Lab 200': {
    user: 'employer',
    password: 'incubate',
    video: 'mzJyFK_69Ko'
  },

  'Log Rhythm': {
    user: 'employer',
    password: 'woodgrains',
    video: 'ZmXuH8RvRqU'
  },

  'Oaktown Spice': {
    user: 'employer',
    password: 'umami',
    video: 'h4ca1F0uu0A'
  },

  'Pop Out Kitchen': {
    user: 'employer',
    password: 'automation',
    video: 'C2JSNP9h1qQ'
  },

  'Seven Hills Baking Co': {
    user: 'employer',
    password: 'hotcoco',
    video: '638ZNruJB6U'
  },

  // ALEX CZECH AND SANTA CLARA COUNTY OFFICE OF ED

  'Cattos Graphics': {
    user: 'employer',
    password: 'screenprint',
    video: 'b4JTd3RrQJc'
  },

  'Authentic Imprints': {
    user: 'employer',
    password: 'screenprint',
    video: 'GjQxoDy0kf0'
  },

  // ANTIOCH UNIFIED

  'Antioch Convalescent Hospital': {
    user: 'employer',
    password: 'seniors',
    video: 'YuKyHYCc0R0'
  },
  'Antioch Police Department Cadet Program': {
    user: 'employer',
    password: 'trust',
    video: 'w2dbDU_Dv3w'
  },

  'Antioch Unified School District HR': {
    user: 'employer',
    password: 'pipeline',
    video: '4Mqrkym2xoQ'
  },

  'Antioch Unified School District IT': {
    user: 'employer',
    password: 'digitalequity',
    video: 'gVbfZ-f1bzg'
  },

  'Antioch Veterinary Hospital': {
    user: 'employer',
    password: 'puppies',
    video: 'wyY3oDuvc5Q'
  },

  'Brentwood Childrens Dentistry': {
    user: 'employer',
    password: 'teeth',
    video: 'jIYl4XezioE'
  },

  'City Of Antioch Recreation': {
    user: 'employer',
    password: 'citywide',
    video: 'l8opHaBWGjc'
  },

  'Contra Costa Water District': {
    user: 'employer',
    password: 'goodwater',
    video: 'XZRMv18C1_8'
  },

  'Delta Diablo Sanitation District': {
    user: 'employer',
    password: 'goodwater',
    video: 'at9e6CXcZJE'
  },

  'East Bay Regional Parks District': {
    user: 'employer',
    password: 'parks',
    video: 'qvgLQlk2m6g'
  },

  'Fellowship Church': {
    user: 'employer',
    password: 'faith',
    video: '3vQZ6291T_8'
  },

  'John Muir Medical Center': {
    user: 'employer',
    password: 'publichealth',
    video: 'COVwumbXSQU'
  },

  Kindercare: {
    user: 'employer',
    password: 'earlyed',
    video: 'rJQ94xrFsJE'
  },

  'Lawrence Livermore National Laboratory': {
    user: 'employer',
    password: 'research',
    video: 'y0b17Z19cO0'
  },

  'Lone Tree Golf And Event Center': {
    user: 'employer',
    password: 'experience',
    video: 'GjxHU_VnTbk'
  },

  'Solar Shine': {
    user: 'employer',
    password: 'pv',
    video: '2vhbC5kr1SA'
  },

  'Sutter Delta Medical Center': {
    user: 'employer',
    password: 'publichealth',
    video: 'pOZZHdZJlUw'
  },

  'The Learning Experience': {
    user: 'employer',
    password: 'earlyed',
    video: 'D4s2FzxRrsw'
  },

  'Tre Vista': {
    user: 'employer',
    password: 'livingthelife',
    video: 'kG9-ZWUlKMA'
  },

  'Umbrella Of Hope': {
    user: 'employer',
    password: 'pets',
    video: 'EA_b5XGy9YE'
  },

  'Westmont Of Brentwood': {
    user: 'employer',
    password: 'seniors',
    video: 'OcSX7WrsMII'
  },

  'Amazon Workforce Staffing': {
    user: 'employer',
    password: 'antioch',
    video: '_SC0AFUqUPk'
  },

  // EGUSD

  'Craig Todd': {
    user: 'employer',
    password: 'biz',
    video: 'vneMTOP_2W4'
  },

  EAA: {
    user: 'employer',
    password: 'fly',
    video: 'WOEQu2AVotc'
  },

  'Elk Grove Unified College And Career': {
    user: 'employer',
    password: 'transform',
    video: 'yLtbEzqilOM'
  },

  'Elk Grove Unified Communications': {
    user: 'employer',
    password: 'connect',
    video: 'hO6DJ8Eayko'
  },

  'Elk Grove Unified Facilities': {
    user: 'employer',
    password: 'maintain',
    video: 'vjxQIubf2bM'
  },

  'Elk Grove Unified Family And Community Engagement': {
    user: 'employer',
    password: 'engage',
    video: '8u6B6pQImaI'
  },

  'Elk Grove Unified Printing Services': {
    user: 'employer',
    password: '11x17',
    video: '9iogbHTK5TY'
  },

  'Elk Grove Unified Transportation': {
    user: 'employer',
    password: 'fieldtrip',
    video: 'reyokV0lK2w'
  },

  Lennar: {
    user: 'employer',
    password: 'buildhomes',
    video: 'Zl9yx9IeoF0'
  },

  'North State Building Industry Foundation': {
    user: 'sponsor',
    password: 'wbl',
    video: 'XPyQSesFq4Y'
  },

  'Sacramento Fire Department': {
    user: 'employer',
    password: 'fightfires',
    video: 'uHstMk0w1C0'
  },

  'Villara Building Systems': {
    user: 'employer',
    password: 'hvac',
    video: 'daNvOzurBz0'
  },

  'Well Space Health': {
    user: 'employer',
    password: 'healthcare',
    video: 'cl5d6T7gnnQ'
  },

  'Elk Grove Employers': {
    user: 'employer',
    password: 'talent',
    video: 'hQBjenUN5Mg'
  }
};

export const INVITE_TESTIMONIALS = [
  {
    background: 'https://f.hubspotusercontent00.net/hubfs/2480959/19500_green_darkgreenstripes.svg',
    icon: 'mdi-comment-quote',
    testimonial:
      'PilotCity was a fresh breath of air. It was a new program that was offering a mind-blowing and unique experience.',
    user: {
      avatar:
        'https://f.hubspotusercontent00.net/hubfs/2480959/Screen%20Shot%202020-08-31%20at%2012.33.36%20AM.png',
      name: 'Amratha R.',
      position: 'Healthtech Entrepreneur, PilotCity'
    }
  },
  {
    background: 'https://f.hubspotusercontent00.net/hubfs/2480959/19500_pink-1.svg',
    icon: 'mdi-comment-quote',
    testimonial:
      'My child has grown right before my eyes. She has matured into a young woman who can achieve what she sets her mind to thanks to this program.',
    user: {
      avatar:
        'https://f.hubspotusercontent00.net/hubfs/2480959/Screen%20Shot%202020-08-31%20at%2012.33.11%20AM.png',
      name: 'Vanesa C.',
      position: 'PilotCity Parent & Guardian'
    }
  },
  {
    background: 'https://f.hubspotusercontent00.net/hubfs/2480959/19500_blue-3.svg',
    icon: 'mdi-comment-quote',
    testimonial:
      'Most employers wouldn’t want high schoolers to work for them since there’s a stigma that we aren’t prepared and under-qualified. PilotCity pushes that stigma away and recognizes the capability of high schoolers to do something that isn’t normally seen among us.',
    user: {
      avatar: 'https://f.hubspotusercontent00.net/hubfs/2480959/_DSC4843_small.jpg',
      name: 'Rosa S.',
      position: 'Intern, San Leandro Library'
    }
  },
  {
    background: 'https://f.hubspotusercontent00.net/hubfs/2480959/19500_purple-2.svg',
    icon: 'mdi-comment-quote',
    testimonial:
      'PilotCity is an excellent program for high school kids as it gives them invaluable experience in entrepreneurship, confidence, and team building as it pushes kids to the limits. It teaches them real-life experiences. A program must for all high school kids.',
    user: {
      avatar: 'https://f.hubspotusercontent00.net/hubfs/2480959/3_SeeProjectExamples-1-1.png',
      name: 'Shuchita G.',
      position: 'PilotCity Parent & Guardian'
    }
  },
  {
    background: 'https://f.hubspotusercontent00.net/hubfs/2480959/19500_red-2.svg',
    icon: 'mdi-comment-quote',
    testimonial:
      "Being with PilotCity has allowed me to transform and grow in so many ways. Thanks to this experience, I'm going to be working with my employer to implement my service past this internship. The PilotCity team is full of supportive, hard-working people, and I'm lucky to have been able to join this experience this summer. Thank you PilotCity!",
    user: {
      avatar:
        'https://f.hubspotusercontent00.net/hubfs/2480959/Screen%20Shot%202020-08-31%20at%2012.09.15%20AM-1.png',
      name: 'Allyson L.',
      position: 'Intern, City of East Palo Alto'
    }
  },
  {
    background:
      'https://f.hubspotusercontent00.net/hubfs/2480959/19500_yellow_darkyellowstripes.svg',
    icon: 'mdi-comment-quote',
    testimonial:
      'Through PilotCity, my incoming 10th-grade daughter got internships with two different companies during the Summer of 2020. PilotCity encouraged her to accept both the offers, which helped her realize her own capacity and learn how to manage her time well. That exposure helped her improve her technical, presentation, and collaboration skills, as well as boosted her confidence.',
    user: {
      avatar:
        'https://f.hubspotusercontent00.net/hubfs/2480959/Screen%20Shot%202020-08-31%20at%2012.32.52%20AM.png',
      name: 'Rashmi K.',
      position: 'PilotCity Parent & Guardian'
    }
  },
  {
    background:
      'https://f.hubspotusercontent00.net/hubfs/2480959/19500_orange_darkorangestripes.svg',
    icon: 'mdi-comment-quote',
    testimonial:
      "The second time around with PilotCity was amazing. Even though I didn't have a main project this year, I got to work under someone and learn about the workforce. I got to improve my skill level. And I really enjoyed learning something different every week.",
    user: {
      avatar:
        'https://f.hubspotusercontent00.net/hubfs/2480959/Screen%20Shot%202020-08-31%20at%2012.31.51%20AM.png',
      name: 'Kyara G.',
      position: 'Intern, Shannon Leigh'
    }
  },
  {
    background: 'https://f.hubspotusercontent00.net/hubfs/2480959/19500_green_darkgreenstripes.svg',
    icon: 'mdi-comment-quote',
    testimonial:
      'What a great experience. My daughter, a rising Sophomore, signed up for a weekend hackathon program. A weekend turned out to be the entire summer. She loved the experience so much that she made a proposal to her employer to continue through the school year. Besides the obvious benefits of this work experience for her college resume, she built up her self confidence and leadership skills.',
    user: {
      avatar:
        'https://f.hubspotusercontent00.net/hubfs/2480959/Screen%20Shot%202020-08-31%20at%2012.33.56%20AM.png',
      name: 'Jeff L.',
      position: 'PilotCity Parent & Guardian'
    }
  },
  {
    background: 'https://f.hubspotusercontent00.net/hubfs/2480959/19500_pink-1.svg',
    icon: 'mdi-comment-quote',
    testimonial: '"PilotCity is more than just a program... it\'s a family"',
    user: {
      avatar:
        'https://f.hubspotusercontent00.net/hubfs/2480959/Screen%20Shot%202020-08-31%20at%2012.34.07%20AM.png',
      name: 'Pranav P.',
      position: 'MESH Fellow, Takeoff Point'
    }
  }
];
