var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('AImage',{staticClass:"height-full bg-black",attrs:{"src":"https://f.hubspotusercontent00.net/hubfs/2480959/PC_Hero_3-1.jpg","max-height":"100vh","height":"100%"}},[_c('Loading',{attrs:{"callback":_vm.login,"linear-loader":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var loading = ref.loading;
var process = ref.process;
return [_c('div',{staticClass:"d-flex justify-center"},[_c('div',{staticClass:"login__title info--text text-h4 font-weight-bold"},[_c('div',{staticClass:"d-flex justify-center text-align"},[_c('v-icon',{attrs:{"color":"blue","x-large":""}},[_vm._v("mdi-telegram")])],1),_c('span',[_vm._v("Login")])])]),_c('MAuthInputs',[_c('validation-observer',{ref:"observer",staticClass:"login__emailalign",attrs:{"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('validation-provider',{ref:"provider",attrs:{"rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('ATextInput',{staticClass:"login__input",attrs:{"label":"Email","placeholder":"Email","errors":errors,"rounded":"","single-line":"","outlined":"","full-width":"","dark":""},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}})]}}],null,true)}),_c('validation-provider',{attrs:{"slim":"","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('ATextInput',{staticClass:"login__input",attrs:{"label":"Password","placeholder":"Password","errors":errors,"rounded":"","single-line":"","outlined":"","full-width":"","dark":"","type":_vm.show1 ? 'text' : 'password',"append-icon":_vm.show1 ? 'mdi-eye' : 'mdi-eye-off',"keyup-enter":process,"toggle-show":_vm.toggleShowPassword},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_c('v-dialog',{attrs:{"width":"400"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"white--text text-right"},'div',attrs,false),on),[_c('i',[_c('a',{staticClass:"login__forgotlink white--text"},[_vm._v(" Forgot Password")])])])]}}],null,true),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',{staticClass:"login__dialog text-center",attrs:{"dark":""}},[_c('div',{staticClass:"d-flex flex-column"},[_c('ATextInput',{attrs:{"rounded":"","outlined":"","label":"Enter your email"},model:{value:(_vm.resetEmail),callback:function ($$v) {_vm.resetEmail=$$v},expression:"resetEmail"}}),_c('AButton',{attrs:{"x-large":"","depressed":"","rounded":"","outlined":"","badge-text":"Send Reset Password Email"},on:{"clickOn":_vm.sendResetPasswordEmail}}),(_vm.msg)?_c('v-alert',{staticClass:"login__alert",attrs:{"type":_vm.type}},[_vm._v(_vm._s(_vm.msg))]):_vm._e()],1)])],1)]}}],null,true)}),_c('AButton',{ref:"loginBtn",staticClass:"mt-6",attrs:{"depressed":"","color":"blue","rounded":"","width":"100%","dark":"","x-large":"","disabled":invalid,"loading":loading,"badge-text":"Login"},on:{"click":process}}),(_vm.error)?_c('v-alert',{staticClass:"login__alert",attrs:{"type":"error"}},[_vm._v(_vm._s(_vm.error))]):_vm._e(),_c('div',{staticClass:"login__newaccount text-center"},[(_vm.isInvite)?_c('router-link',{attrs:{"to":"#"},nativeOn:{"click":function($event){return _vm.$emit('signup', $event)}}},[_c('a',{staticClass:"login__signuplink white--text text-center"},[_vm._v(" No account yet? Signup.")])]):_c('router-link',{attrs:{"to":{ name: 'signup', params: { page: _vm.$route.params.page } }}},[_c('a',{staticClass:"login__signuplink white--text text-center"},[_vm._v(" No account yet? Signup.")])])],1),_c('div',{staticClass:"d-flex justify-center mt-6"},[_c('a',{attrs:{"href":"https://www.pilotcity.com/"}},[_c('img',{staticClass:"nav__logo2",attrs:{"src":require("@/assets/Pilotcity_logo.png")}})])])]}}],null,true)})],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }