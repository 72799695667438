export const PARTNER_PAGES = {
  Gate510: {
    user: 'partner',
    video: 'fuadj026j'
  },
  'Antioch Unified': {
    user: 'partner',
    video: 'fy3r01xkg'
  },
  'Hebron Academy': {
    user: 'partner',
    video: 'fxfz9ha55'
  },
  'Elk Grove Unified': {
    user: 'partner',
    video: 'fyotsqe38'
  },
  Ambayarea: {
    user: 'partner',
    video: 'fm5vckan5'
  },
  'Ebrpd Teachers': {
    user: 'partner',
    video: 'fqrz02pso'
  },
  'Ebrpd Students': {
    user: 'partner',
    video: 'fzqnlanbs'
  },
  '1mc': {
    user: 'partner',
    video: 'fmrb2ntkl'
  }
};

export const PARTNER_TESTIMONIALS = [];
